const SignupPage = ({ onBack }) => {
  return (
    <div>
      <h2>Signup Page</h2>
      {/* Add your signup form here */}
      <button onClick={onBack}>Back</button>
    </div>
  );
};

export default SignupPage;
