export const LOGIN = "/api/users/login";
export const SIGNUP = "/api/users/signup";
export const CREATE_TEAM = "/api/team/create";
export const GET_ALL_TEAM = "/api/team/all";
export const INVITE_USER = "/api/invite/create";
export const GET_TEAM_MEMBER = "/api/team/get/";
export const GET_USERID = "/api/users/byName/";
export const ADD_EDUCATION = "/api/users/add-education";
export const GET_EDUCATION_ENTRIES = "/api/users/get-all-education";
export const EDIT_EDUCATION = "/api/users/edit-education";
export const DELETE_EDUCATION = "/api/users/delete-education";
export const ADD_EXPERIENCE = "/api/users/add-experience";
export const GET_EXPERIENCE_ENTRIES = "/api/users/get-all-experience";
export const EDIT_EXPERIENCE = "/api/users/edit-experience";
export const DELETE_EXPERIENCE = "/api/users/delete-experience";
export const ADD_SKILL = "/api/users/add-skill";
export const GET_ALL_SKILL = "/api/users/get-all-skill";
export const DELETE_SKILL = "/api/users/delete-skill";
export const CREATE_CONTEST = "/api/contest/create-contest";
export const VERIFICATION = "/api/users/verification";
export const GET_ALL_NOTIFICATION = "/api/notification/all";
export const EDIT_INVITE = "/api/invite/accept";
export const DELETE_INVITE = "/api/invite/delete";
export const GET_USER_INFO = "/api/users/byName";
